<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://tingee.vn"
        target="_blank"
      >TINGEE</b-link>
    </span>

    <b-button
      v-if="appName === 'Resident'"
      class="float-md-right d-none d-md-block ml-50"
      variant="outline-warning"
      href="https://zalo.me/3532252916824730470"
      target="_blank"
      size="sm"
    >
      <feather-icon
        icon="LifeBuoyIcon"
        class="mr-50"
      />
      <span class="align-middle">Zalo hỗ trợ</span>
    </b-button>

    <b-button
      v-if="appName === 'Resident'"
      class="float-md-right d-none d-md-block"
      variant="outline-danger"
      href="tel://02471088968"
      size="sm"
    >
      <feather-icon
        icon="PhoneIcon"
        class="mr-50"
      />
      <span class="align-middle">0247 1088 968</span>
    </b-button>
    <!-- <b-button
      class="float-md-right d-none d-md-block mr-50"
      variant="outline-success"
      href="https://docs.resident.vn"
      target="_blank"
      size="sm"
    >
      <feather-icon
        icon="BookOpenIcon"
        class="mr-50"
      />
      <span class="align-middle">Hướng dẫn sử dụng</span>
    </b-button> -->
  </p>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BLink,
    BButton,
  },
  setup() {
    const { appName } = $themeConfig.app;

    return {
      appName,
    };
  },
};
</script>
